import * as React from 'react'
import { Container } from '../components/container'
import { useTheme } from '@conte-ltd/components-theme'
import { Theme } from '../styles/theme'
import { useMediaQuery } from '@conte-ltd/components-utils'
import { Separator } from '@conte-ltd/components-separator'
import { Layout } from '../components/layout'
import { Section } from '../components/section'

const SitePolicyPage = () => {
  const {
    styles: {
      margin,
      padding,
      display,
      flexDirection,
      alignItems,
      gap,
      alignSelf,
      fontFamily,
      fontSize,
      fontWeight,
      letterSpacing,
      lineHeight,
      textAlign,
      maxWidth,
      width,
    },
    breakpoints: bp,
  } = useTheme<Theme>()
  const isMobile = useMediaQuery(bp.lg)
  const isDesktop = !isMobile

  const sitePolicy = [
    {
      heading: '著作権について',
      content:
        '当社ウェブサイトにある文章・画像・動画・商標・肖像等、（以下、「ウェブデータ」）に関する著作権とその他の権利は、当社または原著作者、その他の権利者のものです。企業等が非営利目的で使用する場合、個人的な使用を目的とする場合、その他著作権法により認められている場合を除き、ウェブデータは当社、原著作者、その他の権利者の許諾なく使用することは出来ません。',
    },
    {
      heading: 'リンクについて',
      content:
        '当社はウェブデータの内容、当社ウェブサイトに対し行われるリンクに関し、いかなる保証もいたしません。当社ウェブデータの利用により、万一何らかの障害・不具合等がお客さま側に問題が発生したとしても、当社は一切の責任を負いかねます。当社は事前に調査をし、その時点で問題が無いと判断した場合にのみ他のウェブサイトへのリンクを行っておりますが、当社ウェブサイトから行うリンクに関し、リンク先ウェブサイトの保証をした訳ではございません。',
    },
    {
      heading: (
        <>お客さまのアクセスログ・{isMobile && <br />}履歴情報について</>
      ),
      content:
        '当社のウェブサイトでは、アクセスされた方の情報をアクセスログという形で記録しています。アクセスログは、アクセスされた方のドメイン名やIPアドレス、使用しているブラウザの種類、アクセス日時などが含まれますが、通常は個人を特定できる情報を含むものではありません。これらのアクセスログはウェブサイトの保守管理や利用状況に関する統計分析のために活用されますが、それ以外の目的で利用されることはありません。',
    },
    {
      heading: 'フォーム等の登録について',
      content:
        '予めお客さまが個人情報を当社に登録の上、ご利用いただいているサービスについては、お客さまのご利用に関する履歴情報がお客さまを特定できる情報とあわせて記録されることがございますが、当社は、これらを個人情報の一部として適切に取り扱うものとします。当社が、以下に記載された以外の目的で履歴情報を個人情報と結びつけて利用する場合は、サービス毎に利用目的をお客さまにあらかじめ明示することといたします。',
    },
    {
      heading: (
        <>
          サービスのご利用状況等に関する{isMobile && <br />}お客様へのお知らせ
        </>
      ),
      content: (
        <>
          ○お客さまからの問い合わせへの対応
          <br />
          ○お客さまの個人情報の登録・管理
          <br />
          ○お客さま個人を特定することができない形式による統計資料の作成
          <br />
          当社のウェブサイトからリンクされている他社のウェブサイトにおけるプライバシー情報の取り扱いの基準や内容において、当社は責任を負うものではありません。
          <br />
          当社は、より一層お客さまの個人情報の保護を図るため、もしくは、法令の制定や変更等に伴い、プライバシーポリシーを変更することがありますので、定期的にご確認されることをお勧めいたします。
        </>
      ),
    },
    {
      heading: 'その他',
      content:
        'ウェブサイトのデータ等の記載事項、構成等は、予告なしに変更または中止することがあります。ご了承ください。',
    },
  ]

  return (
    <Layout>
      <Section id={'site-policy'} className={'light-mode'}>
        <Container
          css={[
            isDesktop && padding.x.xl,
            isMobile ? padding.y['4xl'] : padding.y['5xl'],
            display.flex,
            flexDirection.column,
            alignItems.center,
            margin.x.auto,
          ]}
        >
          <h1
            css={[isMobile ? fontSize.xl : fontSize['4xl'], textAlign.center]}
          >
            SITE POLICY
          </h1>

          <h2
            css={[
              isMobile ? fontSize.sm : fontSize.md,
              fontWeight.medium,
              letterSpacing.tighter,
              textAlign.center,
              isMobile ? margin.top['2xs'] : margin.top.sm,
            ]}
          >
            サイトポリシー
          </h2>

          <p css={[margin.top.lg, isDesktop && textAlign.center]}>
            このウェブサイトは株式会社CONTE（以下、当社といいます）が運営しています。
            <br />
            当社ウェブサイトに関してご利用するお客さまにお願いしたいことを以下に掲載いたしました。
            <br />
            皆さまのご協力をお願いいたします。
          </p>

          <div
            css={[
              display.flex,
              flexDirection.column,
              isMobile ? gap.all.xl : gap.all.md,
              margin.top['4xl'],
            ]}
          >
            {sitePolicy.map(({ heading, content }, index) => (
              <section
                key={index}
                css={[display.flex, flexDirection.column, gap.all.sm]}
              >
                <h2
                  css={[
                    fontFamily.jp,
                    fontSize['2lg'],
                    fontWeight.semibold,
                    lineHeight.relaxed,
                    textAlign.center,
                    margin.y.offset.negative.xs,
                  ]}
                >
                  {heading}
                </h2>

                <Separator size={'6rem'} css={alignSelf.center} />

                <p>{content}</p>
              </section>
            ))}
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default SitePolicyPage
